<template>
    <div class="book_comment_con" v-if="getLoginStatus">
        <div class="input-con">
              <textarea key_textarea
                        id="comm"
                        rows='50' cols='3'
                        wrap="physical"
                        class="de_text"
                        placeholder="写下你的想法吧~"
                        v-model="options.input_value"
                        v-focus
              ></textarea>
<!--            <p class="word">{{ options.input_value.length }}/1500</p>-->
        </div>
        <div class="btns">
            <Emoji @chooseemoji="inputEmoji" />
            <button type="button" key-button @click="up_comment">提交评论</button>
        </div>
    </div>
    <div v-else class="needLogin">
        请先 <el-button type="primary" size="mini" @click="login">登录</el-button> 后发表评论(・ω・)
    </div>
</template>
<script>
import Emoji from '@/components/show_book/Emoji';
export default {
    data() {
        return {
            input_value: ''
        }
    },
    components: {
        Emoji
    },
    props: {
        options: {
            type: Object
        }
    },
    computed: {
        getLoginStatus(){
            return this.had_login();
        },
    },
    methods: {
        login(){
            this.$store.commit("set_login_type", true);
        },
        // 发布评论
        up_comment() {
            if (this.options.input_value === '') {
                return;
            } else {
                this.$emit('up_comment');
                this.options.input_value = ''
            }
        },
        inputEmoji(text){
            this.options.input_value += text;
        },
        // 获取当前时间
        get_time() {
            let date = new Date();
            let time = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            return time;
        }
    },
    // 自定义v-focus方法
    directives: {
        focus: {
            inserted(el) {
                el.focus();
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.book_comment_con {
    font-size: 12px;
    padding: 15px 0 25px 0;
    overflow: hidden;

    button {
        background-color: rgba(64, 158, 255, 1);
        color: #ffffff;
        width: 80px;
        height: 32px;
        margin-top: 15px;
        margin-left: 10px;
        float: right;
    }

    button:hover {
        opacity: 0.8;
    }

    div.input-con {
        border: 1px solid #e4e4e4;
        height: 125px;
        padding: 15px 10px 0 10px;

        p {
            text-align: right;
        }

        textarea {
            width: 100%;
            height: 90px;
            // padding: 20px 1px;
        }
    }
    .btns{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.needLogin{
    width: 100%;
    height: 126px;
    line-height: 126px;
    background: #EEEEEE;
    text-align: center;
}

.word {
    color: #c5c5c5
}

textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #c5c5c5;
}

textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c5c5c5;
}

textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c5c5c5;
}

textarea::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #c5c5c5;
}
</style>