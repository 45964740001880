import { render, staticRenderFns } from "./second_comment.vue?vue&type=template&id=4a54d682&scoped=true&"
import script from "./second_comment.vue?vue&type=script&lang=js&"
export * from "./second_comment.vue?vue&type=script&lang=js&"
import style0 from "./second_comment.vue?vue&type=style&index=0&id=4a54d682&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a54d682",
  null
  
)

export default component.exports