<template>
    <div :key="index" class="second-comment-container">
        <div flex class="user_comment_title">
            <div class="second-comment-info">
                <div class="basic-info">
                    <span class="name">{{ data.nickname }}</span>
                    <div class="createtime">
                        {{ data.cre_time }}
                    </div>
                    <span v-if="data.is_author" class="tag red">作者</span>
                    <span v-if="data.is_vip" class="tag red">VIP</span>
                    <span v-if="!!data.level" class="tag">LV{{data.level}}</span>
                </div>
                <div class="second-reply">
                    <a v-if="getMoneyInfo.id === getDetail.author_id || (getMoneyInfo.id === data.user_id)" href="javascript:;" class="delete" @click="authorAdmin(data.id, 2)">删除评论</a>
                    <a href="javascript:;" @click="showReply">回复</a>
                </div>
            </div>
        </div>
        <p class="user_comment_content" words-wrap>
            <a href="javascript:;" v-if="data.to_user && data.to_user.id">【回复@<span>{{data.to_user.nickname}}</span>】</a>
            {{ data.content }}
        </p>
        <div class="reply-input" v-if="showInput">
            <el-input v-model="replyContent"
                      type="textarea" :rows="4"
                      placeholder="在此回复不超过100字"
                      :maxlength="100" show-word-limit
                      size="mini"/>
            <el-button type="primary" size="mini" @click="getParams">回复</el-button>
            <el-button type="default" size="mini" @click="clearReply">取消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "second_comment",
        components: {},
        props: {
            data: Object,
            index: String
        },
        data(){
            return {
                defaultAvator: require('./../../../assets/images/default_header.png'),
                deaultImage: 'this.src="' + require('./../../../assets/images/default_header.png') + '"',
                showInput: false,
                replyContent: '',
                emojiList: [
                    {
                        text: "暗中观察",
                        url: require('./../../../assets/images/emoji/small/watchDark.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/watchDark.png'),
                    },
                    {
                        text: "拜托",
                        url: require('./../../../assets/images/emoji/small/please.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/please.png'),
                    },
                    {
                        text: "不高兴",
                        url: require('./../../../assets/images/emoji/small/unhappy.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/unhappy.png'),
                    },
                    {
                        text: "打霍嗨",
                        url: require('./../../../assets/images/emoji/small/yawn.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/yawn.png'),
                    },
                    {
                        text: "得意",
                        url: require('./../../../assets/images/emoji/small/proud.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/proud.png'),
                    },
                    {
                        text: "发刀片",
                        url: require('./../../../assets/images/emoji/small/blade.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/blade.png'),
                    },
                    {
                        text: "害怕",
                        url: require('./../../../assets/images/emoji/small/fear.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/fear.png'),
                    },
                    {
                        text: "紧张",
                        url: require('./../../../assets/images/emoji/small/nervous.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/nervous.png'),
                    },
                    {
                        text: "沮丧",
                        url: require('./../../../assets/images/emoji/small/depressed.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/depressed.png'),
                    },
                    {
                        text: "开心",
                        url: require('./../../../assets/images/emoji/small/happy.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/happy.png'),
                    },
                    {
                        text: "灵光一现",
                        url: require('./../../../assets/images/emoji/small/flash.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/flash.png'),
                    },
                    {
                        text: "迷惑",
                        url: require('./../../../assets/images/emoji/small/confuse.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/confuse.png'),
                    },
                    {
                        text: "期待",
                        url: require('./../../../assets/images/emoji/small/expect.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/expect.png'),
                    },
                    {
                        text: "生气",
                        url: require('./../../../assets/images/emoji/small/angry.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/angry.png'),
                    },
                    {
                        text: "无语",
                        url: require('./../../../assets/images/emoji/small/speechless.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/speechless.png'),
                    },
                    {
                        text: "震惊",
                        url: require('./../../../assets/images/emoji/small/shock.png'),
                        bigUrl: require('./../../../assets/images/emoji/big/shock.png'),
                    },
                ]
            }
        },
        computed: {
            getMoneyInfo() {
                return this.$store.state.money_info;
            },
            getDetail() {
                return this.$store.state.book_info;
            },
        },
        methods: {
            showReply(){
                this.showInput = !this.showInput;
            },
            clearReply(){
                this.showInput = false;
                this.replyContent = '';
            },
            getParams(){
                if(!this.replyContent.length){
                    this.$message.warning('请输入内容');
                    return ;
                }
                let params = {
                    books_id: this.$store.state.book_info.id,
                    content: this.replyContent,
                    to_user_id: this.data.user_id
                };
                this.$emit('submitReply', params, ()=>{
                    this.showInput = false;
                    this.replyContent = '';
                })
            },
            authorAdmin(comment_id, type){
                this.$emit('authorAdmin', comment_id, type);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .second-comment-container{
        padding: 18px 10px;
        background: #F4F4F5;
        .second-comment-info{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            span{
                display: inline-block;
                line-height: 20px;
            }
            .basic-info{
                display: flex;
                .createtime{
                    margin-left: 22px;
                    margin-right: 15px;
                    color: #999;
                    font-size: 14px;
                }
            }
        }
        .second-reply{
            display: flex;
            justify-content: flex-end;
            padding-right: 3%;
            a{
                margin-left: 40px;
                color: #409EFF !important;
                &.delete{
                    color: #D9001B!important;
                }
            }
        }
        .user_comment_content{
            color: #999!important;
            a{
                color: #999!important;
            }
        }
    }
</style>
