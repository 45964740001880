<template>
  <div class="user_comment">
    <div
      class="dashed"
      :style="index ? 'display:block;' : 'display:none'"
    ></div>
    <img
      v-if="options.is_essence"
      class="essence"
      src="./../../../assets/images/details/essence.png"
      alt=""
    />
    <div flex class="user_comment_title">
      <div class="head-image">
        <img
          :src="options.avatar || defaultAvator"
          alt=""
        />
      </div>
      <div class="comment-info">
        <div class="info">
          <div>
            <span @click="user_info" class="name">{{ options.nickname }}</span>
            <span v-if="options.is_author" class="tag red">作者</span>
            <span v-if="options.is_vip" class="tag red">VIP</span>
            <span v-if="!!options.level" class="tag"
              >LV{{ options.level }}</span
            >
          </div>

          <div :class="'reply-container'">
            <a
              v-if="
                getMoneyInfo.id === getDetail.author_id ||
                getMoneyInfo.id === options.user_id
              "
              href="javascript:;"
              class="delete"
              @click="authorAdmin(options.id, 2)"
              >删除评论</a
            >
            <a
              v-if="getMoneyInfo.id === getDetail.author_id"
              href="javascript:;"
              @click="authorAdmin(options.id, 1)"
              >{{ options.is_essence ? "取消加精" : "加精" }}</a
            >
            <a href="javascript:;"><span @click="showReply">回复 </span></a>
          </div>
        </div>
        <div class="createtime">
          {{ options.cre_time }}
        </div>
      </div>
    </div>
    <div class="gift-show" v-if="options.icon">
      <img :src="options.icon" alt="" />
      <div class="num">x {{ options.give_num }}</div>
    </div>
    <p
      class="user_comment_content"
      v-html="$options.filters.getEmoji(options.content)"
      words-wrap
    ></p>
    <div class="reply-input" v-if="showInput">
      <el-input
        v-model="replyContent"
        type="textarea"
        :rows="4"
        placeholder="在此回复不超过100字"
        maxlength="100"
        show-word-limit
        size="mini"
      />
      <el-button type="primary" size="mini" @click="getParams">发表</el-button>
      <el-button type="default" size="mini" @click="clearReply">取消</el-button>
    </div>
    <div :class="{ 'second-list': true, 'second-list-nobg': !showSecondList }">
      <secondComment
        v-for="(item, index) in options.child"
        :data="item"
        v-show="index < 1 || (index >= 1 && showSecondList)"
        @authorAdmin="authorAdmin"
        @submitReply="submitReply"
        :key="item.id"
        :index="'second_' + item.id"
      ></secondComment>
    </div>
    <div class="more" v-if="options.child.length > 1 && !showSecondList">
      <a href="javascript:;" @click="showSecondComment(options)"
        >查看所有回复({{ options.child.length }})</a
      >
    </div>
  </div>
</template>
<script>
import {
  add_review,
  authorCommentOperation,
  delete_self_comment,
} from "@/https/read_books.js";
import secondComment from "./second_comment";

export default {
  data() {
    return {
      defaultAvator: require("./../../../assets/images/default_header.png"),
      deaultImage:
        'this.src="' +
        require("./../../../assets/images/default_header.png") +
        '"',
      showInput: false, //是否展示回复输入框
      replyContent: "", //回复内容
      showSecondList: false, //展示二级评论列表
    };
  },
  components: {
    secondComment,
  },
  computed: {
    getMoneyInfo() {
      return this.$store.state.money_info;
    },
    getDetail() {
      return this.$store.state.book_info;
    },
  },
  props: {
    options: {
      type: Object,
    },
    is_author: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    user_info() {
      // 跳转到用户详细信息页面
    },
    clearReply() {
      this.showInput = false;
      this.replyContent = "";
    },
    showReply() {
      this.showInput = !this.showInput;
    },
    showSecondComment(item) {
      if (item && item.child && item.child.length) {
        this.showSecondList = !this.showSecondList;
      }
    },
    getParams() {
      if (!this.replyContent.length) {
        this.$message.warning("请输入内容");
        return;
      }
      let params = {
        books_id: this.$store.state.book_info.id,
        content: this.replyContent,
        reply_id: this.options.id,
      };
      this.submitReply(params);
    },
    submitReply(params, successCallback) {
      if (!params.reply_id) params.reply_id = this.options.id;
      add_review(params).then((res) => {
        if (res.code === 0) {
          this.$emit("refreshlist");
          this.replyContent = "";
          this.showInput = false;
          successCallback && successCallback();
        }
      });
    },
    authorAdmin(comment_id, type) {
      if (
        type === 2 &&
        this.getMoneyInfo.id === (this.options && this.options.user_id)
      ) {
        this.deleteSelfComment(comment_id);
        return;
      }
      authorCommentOperation({
        comment_id,
        type,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.$store.state.messageVue.$emit("refreshCommentList");
        }
      });
    },
    deleteSelfComment(comment_id) {
      delete_self_comment({
        comment_id,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.$store.state.messageVue.$emit("refreshCommentList");
        }
      });
    },
  },
  filters: {
    getEmoji(value) {
      const emojiList = [
        {
          text: "暗中观察",
          url: require("./../../../assets/images/emoji/small/watchDark.png"),
          bigUrl: require("./../../../assets/images/emoji/big/watchDark.png"),
        },
        {
          text: "拜托",
          url: require("./../../../assets/images/emoji/small/please.png"),
          bigUrl: require("./../../../assets/images/emoji/big/please.png"),
        },
        {
          text: "不高兴",
          url: require("./../../../assets/images/emoji/small/unhappy.png"),
          bigUrl: require("./../../../assets/images/emoji/big/unhappy.png"),
        },
        {
          text: "打霍嗨",
          url: require("./../../../assets/images/emoji/small/yawn.png"),
          bigUrl: require("./../../../assets/images/emoji/big/yawn.png"),
        },
        {
          text: "得意",
          url: require("./../../../assets/images/emoji/small/proud.png"),
          bigUrl: require("./../../../assets/images/emoji/big/proud.png"),
        },
        {
          text: "发刀片",
          url: require("./../../../assets/images/emoji/small/blade.png"),
          bigUrl: require("./../../../assets/images/emoji/big/blade.png"),
        },
        {
          text: "害怕",
          url: require("./../../../assets/images/emoji/small/fear.png"),
          bigUrl: require("./../../../assets/images/emoji/big/fear.png"),
        },
        {
          text: "紧张",
          url: require("./../../../assets/images/emoji/small/nervous.png"),
          bigUrl: require("./../../../assets/images/emoji/big/nervous.png"),
        },
        {
          text: "沮丧",
          url: require("./../../../assets/images/emoji/small/depressed.png"),
          bigUrl: require("./../../../assets/images/emoji/big/depressed.png"),
        },
        {
          text: "开心",
          url: require("./../../../assets/images/emoji/small/happy.png"),
          bigUrl: require("./../../../assets/images/emoji/big/happy.png"),
        },
        {
          text: "灵光一现",
          url: require("./../../../assets/images/emoji/small/flash.png"),
          bigUrl: require("./../../../assets/images/emoji/big/flash.png"),
        },
        {
          text: "迷惑",
          url: require("./../../../assets/images/emoji/small/confuse.png"),
          bigUrl: require("./../../../assets/images/emoji/big/confuse.png"),
        },
        {
          text: "期待",
          url: require("./../../../assets/images/emoji/small/expect.png"),
          bigUrl: require("./../../../assets/images/emoji/big/expect.png"),
        },
        {
          text: "生气",
          url: require("./../../../assets/images/emoji/small/angry.png"),
          bigUrl: require("./../../../assets/images/emoji/big/angry.png"),
        },
        {
          text: "无语",
          url: require("./../../../assets/images/emoji/small/speechless.png"),
          bigUrl: require("./../../../assets/images/emoji/big/speechless.png"),
        },
        {
          text: "震惊",
          url: require("./../../../assets/images/emoji/small/shock.png"),
          bigUrl: require("./../../../assets/images/emoji/big/shock.png"),
        },
      ];
      return value.replace(/\[.*?\]/g, function (val) {
        if (val) {
          let emojiText = val.substring(1, val.length - 1);
          if (!emojiText) return "";
          let emoji = emojiList.find((item) => item.text === emojiText);
          if (!emoji) return "";
          return `<img src=${emoji.bigUrl} />`;
        }
        return "";
      });
    },
  },
};
</script>
<style lang="scss">
.user_comment {
  position: relative;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 15px 14px;
  background-color: #fbfbfb;

  img.essence {
    position: absolute;
    width: 92px;
    height: 92px;
    top: 50%;
    right: 30%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  .dashed {
    position: absolute;
    top: 0;
    left: 0;
    // width: calc(100% - 40px);
    width: 100%;
    height: 1px;
    background-size: 8px 1px;
    background-repeat: repeat-x;
  }

  .user_comment_title {
    padding: 2px 10px 10px 10px;
    align-items: center;

    .head-image {
      width: 61px;
      height: 61px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .comment-info {
      flex-grow: 2;
      .info {
        display: flex;
        justify-content: space-between;
        .reply-container {
          text-align: right;
          a {
            margin-left: 40px;
            padding: 5px 0;
            color: #409eff !important;
            font-size: 14px;
            &.delete {
              color: #d9001b !important;
            }
            &.active {
              background: #eee;
            }
          }
          img.active {
            transform: rotateZ(-180deg);
          }
        }
      }
      .createtime {
        margin-top: 6px;
        font-size: 14px;
        color: #999;
      }
    }

    .name {
      margin-right: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: #409eff;
    }

    .tag {
      display: inline-block;
      width: 34px;
      line-height: 20px;
      margin-left: 5px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      background-color: #409eff;
      &.red {
        background-color: #d9001b;
      }
    }
  }

  .gift-show {
    display: flex;
    padding-left: 20px;
    margin-bottom: 15px;
    align-items: center;
    img {
      max-height: 78px;
    }
    .num {
      padding-left: 35px;
    }
  }
  .user_comment_content {
    position: relative;
    padding: 5px 15px;
    z-index: 1;
    img {
      width: 40px;
      margin: 0 6px;
    }
  }

  .reply-input {
    display: block;
    padding: 13px 26px;
    .el-textarea {
      display: block;
      width: 480px;
      margin-bottom: 15px;
    }
    button {
      margin: 0 15px 0 0;
    }
  }
  .second-list {
    & > div {
      border-bottom: 1px solid #ccc;
      &:nth-last-child(1) {
        border: none;
      }
    }
  }
  .second-list-nobg > div {
    background-color: transparent !important;
  }
  .more {
    margin: 9px 0;
    font-size: 12px;
    text-align: right;
    a {
      color: #409eff !important;
    }
  }
}
</style>
<style lang="scss">
</style>
