<template>
  <div>
    <el-popover
      placement="top-start"
      v-model="visible"
      title="鲸云娘"
      width="500"
      trigger="hover"
    >
      <div class="big_img_box"><img src="" alt="" class="big_img" /></div>
      <div class="emoji-list">
        <div
          v-for="item in emojiList"
          :key="item.text"
          :title="item.text"
          @click="chooseEmoji(item)"
          @mouseenter="moveInImg($event)"
          @mouseleave="moveOutImg($event)"
        >
          <img :src="item.url" alt="" />
        </div>
      </div>
      <div slot="reference" class="Emoji">
        <img :src="emojiIcon" alt="" />表情
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "Emoji",
  data() {
    return {
      visible: false,
      emojiIcon: require("./../../assets/images/emoji/emoji.png"),
      emojiList: [
        {
          text: "暗中观察",
          url: require("./../../assets/images/emoji/small/watchDark.png"),
          bigUrl: require("./../../assets/images/emoji/big/watchDark.png"),
        },
        {
          text: "拜托",
          url: require("./../../assets/images/emoji/small/please.png"),
          bigUrl: require("./../../assets/images/emoji/big/please.png"),
        },
        {
          text: "不高兴",
          url: require("./../../assets/images/emoji/small/unhappy.png"),
          bigUrl: require("./../../assets/images/emoji/big/unhappy.png"),
        },
        {
          text: "打霍嗨",
          url: require("./../../assets/images/emoji/small/yawn.png"),
          bigUrl: require("./../../assets/images/emoji/big/yawn.png"),
        },
        {
          text: "得意",
          url: require("./../../assets/images/emoji/small/proud.png"),
          bigUrl: require("./../../assets/images/emoji/big/proud.png"),
        },
        {
          text: "发刀片",
          url: require("./../../assets/images/emoji/small/blade.png"),
          bigUrl: require("./../../assets/images/emoji/big/blade.png"),
        },
        {
          text: "害怕",
          url: require("./../../assets/images/emoji/small/fear.png"),
          bigUrl: require("./../../assets/images/emoji/big/fear.png"),
        },
        {
          text: "紧张",
          url: require("./../../assets/images/emoji/small/nervous.png"),
          bigUrl: require("./../../assets/images/emoji/big/nervous.png"),
        },
        {
          text: "沮丧",
          url: require("./../../assets/images/emoji/small/depressed.png"),
          bigUrl: require("./../../assets/images/emoji/big/depressed.png"),
        },
        {
          text: "开心",
          url: require("./../../assets/images/emoji/small/happy.png"),
          bigUrl: require("./../../assets/images/emoji/big/happy.png"),
        },
        {
          text: "灵光一现",
          url: require("./../../assets/images/emoji/small/flash.png"),
          bigUrl: require("./../../assets/images/emoji/big/flash.png"),
        },
        {
          text: "迷惑",
          url: require("./../../assets/images/emoji/small/confuse.png"),
          bigUrl: require("./../../assets/images/emoji/big/confuse.png"),
        },
        {
          text: "期待",
          url: require("./../../assets/images/emoji/small/expect.png"),
          bigUrl: require("./../../assets/images/emoji/big/expect.png"),
        },
        {
          text: "生气",
          url: require("./../../assets/images/emoji/small/angry.png"),
          bigUrl: require("./../../assets/images/emoji/big/angry.png"),
        },
        {
          text: "无语",
          url: require("./../../assets/images/emoji/small/speechless.png"),
          bigUrl: require("./../../assets/images/emoji/big/speechless.png"),
        },
        {
          text: "震惊",
          url: require("./../../assets/images/emoji/small/shock.png"),
          bigUrl: require("./../../assets/images/emoji/big/shock.png"),
        },
      ],
    };
  },
  methods: {
    chooseEmoji(item) {
      this.$emit("chooseemoji", `[${item.text}]`);
      this.visible = false;
    },
    moveInImg(e) {
      let bigImg = document.querySelector(".big_img");
      //   console.log(e.target.childNodes[0].currentSrc);
      bigImg.src = e.target.childNodes[0].currentSrc;
      bigImg.style.cssText = "display:block";
    },
    moveOutImg(e) {
      let bigImg = document.querySelector(".big_img");
      bigImg.style.cssText = "display:none";
    },
  },
};
</script>

<style lang="scss">
.Emoji {
  width: 78px;
  padding: 4px 0;
  background: #eeeeee;
  color: #999;
  line-height: 15px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  img {
    width: 15px;
    margin-right: 8px;
    vertical-align: top;
  }
}
.big_img_box {
  position: absolute;
  top: -134px;
  left: 364px;
  z-index: 999;
  background-color: #fff;
  img {
    width: 134px;
    height: 134px;
    display: none;
  }
}
.emoji-list {
  & > div {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: -1px;
    margin-left: -1px;
    padding: 9px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
